import actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  evidenceRequiredCandidates: [],
  editCandidateFileStatus: {
    isFileClean: true,
    isUploadScanInProgress: false,
  },
};
const editCandidateDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.EDIT_CANDIDATE_DETAILS: {
      return {
        ...state,
        editCandidateDetails: action.payload,
      };
    }

    case actionTypes.EVIDENCE_REQUIRED_CANDIDATES: {
      const newState = JSON.parse(JSON.stringify(state));
      let searchIndex = newState.evidenceRequiredCandidates.findIndex(
        (v) => v.candidateUUID === action.payload.candidateUUID
      );
      const details = {
        candidateNumber: action.payload.candidateNumber,
        candidateUUID: action.payload.candidateUUID,
        isHighRiskFlag: action.payload.isHighRiskFlag,
      };
      const updateEvidenceCandidateListFlag =
        action.payload.updateEvidenceCandidateListFlag;

      if (updateEvidenceCandidateListFlag === "add") {
        if (searchIndex === -1) {
          searchIndex = 0;
          let evidenceRequiredCandidateDetails =
            newState.evidenceRequiredCandidates;
          evidenceRequiredCandidateDetails.push(details);
          newState.evidenceRequiredCandidates =
            evidenceRequiredCandidateDetails;
        } else {
          newState.evidenceRequiredCandidates[searchIndex].isHighRiskFlag =
            action.payload.isHighRiskFlag;
        }
      } else if (updateEvidenceCandidateListFlag === "remove") {
        let updatedState = newState.evidenceRequiredCandidates.filter(
          (v) => v.candidateUUID !== action.payload.candidateUUID
        );
        newState.evidenceRequiredCandidates = updatedState;
      } else if (updateEvidenceCandidateListFlag === "updateNumber") {
        const searchIndexAt = newState.evidenceRequiredCandidates.findIndex(
          (v) => v.candidateUUID === action.payload.candidateUUID
        );
        if (searchIndexAt !== -1) {
          newState.evidenceRequiredCandidates[searchIndexAt].candidateNumber =
            action.payload.candidateNumber;
        }
      }
      return newState;
    }

    case actionTypes.EDIT_CANDIDATE_UPLOADED_FILES_STATUS: {
      let isFileClean = true;
      let isUploadScanInProgress = false;
      const fileUpdScanInProgressStatus = ["Uploading", "Checking for viruses"];
      const fileInProcessError = [
        "infected",
        "on",
        "upload failure",
        "Error: There was a problem uploading this file. Please delete it and re-upload.",
      ];
      const candidateDetails = action.payload.candidateDetails;
      if (
        typeof candidateDetails.evidenceDetails !== "undefined" &&
        candidateDetails.evidenceDetails.length > 0
      ) {
        candidateDetails.evidenceDetails.find((ele) => {
          if (fileInProcessError.includes(ele.evidenceStatus)) {
            isFileClean = false;
          }
          return "";
        });
        candidateDetails.evidenceDetails.find((ele) => {
          if (fileUpdScanInProgressStatus.includes(ele.evidenceStatus)) {
            isUploadScanInProgress = true;
          }
          return "";
        });
      }

      return {
        ...state,
        editCandidateFileStatus: {
          isFileClean: isFileClean,
          isUploadScanInProgress: isUploadScanInProgress,
        },
      };
    }

    default:
      return state;
  }
};

export default editCandidateDetails;
