import actionTypes from "../actions/actionTypes";
import content from "../util/content";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

let INITIAL_STATE = {
  "requestID": null,
  "type": "AA",
  "reqNumber": null,
  "version": 1,
  "reqStatus": "Saved",
  "submittingCentreName": "AA Dummy Centre B (CIC)",
  "submittingCentreNumber": "",
  "submittingCentreCountry": "",
  "createdOn": null,
  "examSeries": "",
  "updatedBy": "",
  "updatedOn": "",
  "isOutcomeSent": false,
  "parentCentre": null,
  "parentReviewerEmail": null,
  candidateDetails: [
    {
      isActive: true,
      arrangementStatus: null,
      updatedStatus: "Added",
      status: null,
      candidateUUID: uuidv4(),
      qualificationLevel: null,
      requestID: null,
      candidateID: null,
      firstName: "",
      lastName: "",
    },
  ],
};

const newCandidateDetailsObj = {
  ...content.aaArrangementList,
  usEvidenceType : "",
  usEvidenceSummary : "",
  usEvidenceExp : "",
  usEvidencePrev : "",
  usEvidencePrevText : "",
  evidenceDetails : [],
  lastName : "",
  firstName : "",
  candidateNumber : "",
  isActive : true,
  qualificationLevel : "",
  updatedStatus : "Added",
  candidateID : null,
  
}
function removeObjectWithId(arr, id) {
  if (id > -1) {
    arr.splice(id, 1);
  }

  return arr;
}

const accessArrangementData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.EXAM_SERIES: {
      return {
        ...state,
        examSeries: action.payload,
      };
    }
    case actionTypes.UPDATE_REQUEST_PAYLOAD: {
      return {
        ...state,
        examSeries: action.payload.examSeries,
        requestID: action.payload.requestID,
        reqNumber: action.payload.reqNumber
      };
    }
    case actionTypes.BUILD_SUBMIT_PAYLOAD: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails = action.payload;
      return newState;
    }
    case actionTypes.UPDATE_PAYLOAD: {
      return {
        ...state,
        submittingCentreName: action.payload.centerName,
        submittingCentreNumber: action.payload.centerNumber,
        submittedEmail: action.payload.submittedEmail,
      };
    }
    case actionTypes.RESET_PARENTCENTRE:
      return {
        ...state,
        parentCentre: null,
      };

    case actionTypes.GET_CENTRE_COUNTRY_C4C_DATA:
      //todo: need to change submittingCentreCountry back the commented code
      return {
        ...state,
        submittingCentreCountry: action.payload.primaryContact.country.countryName,
      };

    case actionTypes.GET_PARENTCENTRE_OF_CHILD_DATA: {
      //todo: need to change submittingCentreCountry back the commented code
      const centerType = action.centerType;
      if (centerType === 'child') {
        return {
          ...state,
          parentCentre: action.payload.centre.headCentre.id,
        };
      } else if (centerType === 'parent') {
        return {
          ...state,
          parentCentre: action.payload,
        };
      } else {
        return { ...state }
      }
    }

    case actionTypes.UPDATE_STORE: {
      const newState = JSON.parse(JSON.stringify(action.payload));
      let candidateDetailsObj = action.payload.candidateDetails;
      const filteredCandidateDetailsObj = candidateDetailsObj.filter(
        (item) => item.candidateNumber !== ""
      );
      newState.candidateDetails = filteredCandidateDetailsObj;
      return newState;
    }

    case actionTypes.DELETE_CANDIDATE: {
      const newState = JSON.parse(JSON.stringify(state));
      let updatedList = removeObjectWithId(
        newState.candidateDetails,
        action.payload
      );
      newState.candidateDetails = updatedList;
      return newState;
    }
    case actionTypes.UPDATE_ACTIVE_FLAG: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails[action.payload].updatedStatus = "Deleted";
      newState.candidateDetails[action.payload].isActive = false;
      let filterDeletedList = newState.candidateDetails.filter((v) => {
        if (v.updatedStatus === "Deleted") {
          return v.candidateID && v.candidateID !== "";
        }
        return v;
      });
      newState.candidateDetails = filterDeletedList;
      return newState;
    }

    case actionTypes.QUALIFICATION_LEVEL: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      newState.candidateDetails[searchIndex].qualificationLevel =
        action.payload.qualificationLevelValue;
      return newState;
    }

    case actionTypes.RESET_CANDIDATE_DETAILS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails = INITIAL_STATE.candidateDetails
      return newState;
    }

    case actionTypes.UPDATE_ARRANGEMENT_LIST: {
      const newState = JSON.parse(JSON.stringify(state));
      let vars = action.payload.req.variable;
      newState.candidateDetails[0][vars] = action.payload.req.value;
      return newState;
    }

    case actionTypes.UPDATE_EXISTING_ARRANGEMENT_LIST: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let vars = action.payload.req.variable;
      if (action.payload.isLowRisk) {
        newState.candidateDetails[searchIndex][vars].flag = false;
        newState.candidateDetails[searchIndex][vars].isActive = false;
      } else {
        newState.candidateDetails[searchIndex][vars].flag = false;
        newState.candidateDetails[searchIndex][vars].subjects && newState.candidateDetails[searchIndex][vars].subjects.map((v, i) => {
          if (v.arrangementID) {
            newState.candidateDetails[searchIndex][vars].subjects[
              i
            ].isActive = false;
          } else {
            delete newState.candidateDetails[searchIndex][vars].subjects;
          }
          if (vars === "supplementaryAids" && v.arrangementID) {
            newState.candidateDetails[searchIndex][vars].subjects[0].isOptionActive = false;
            newState.candidateDetails[searchIndex][vars].subjects[0].isOtherActive = false;
          }
          return "";
        });
      }
      return newState;
    }

    case actionTypes.UPDATE_SYLLABUS_AND_COMPONENT_CODE: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let variableName = action.payload.data.variable;
      newState.candidateDetails[searchIndex][variableName].subjects[
        action.payload.data.id
      ] = action.payload.data.syllabusComponentObj;
      return newState;
    }

    case actionTypes.ADD_DIFFERENT_SYLLABUS: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let varName = action.payload.variable;
      if (varName === "exemptionRequest") {
        newState.candidateDetails[searchIndex][varName].subjects.push({
          arrangementID: "",
          syllabus: "",
          options: "",
          components: "",
          isActive: true,
          status: "",
        });
      } else {
        newState.candidateDetails[searchIndex][varName].subjects.push({
          syllabus: "",
          components: "",
          isActive: true,
        });
      }
      return newState;
    }

    case actionTypes.ADD_DIFFERENT_SYLLABUS_EXTRA_TIME: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let varName = action.payload.variable;
      newState.candidateDetails[searchIndex][varName].subjects.push({
        arrangementID: "",
        syllabus: "",
        options: "25",
        components: "",
        isActive: true,
        status: "",
      });
      return newState;
    }


    case actionTypes.ADD_DIFFERENT_SYLLABUS_SCRIBE: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let varName = action.payload.variable;
      newState.candidateDetails[searchIndex][varName].subjects.push({
        arrangementID: "",
        syllabus: "",
        options: "",
        components: "",
        isActive: true,
        status: "",
      });
      return newState;
    }

    case actionTypes.DELETE_THE_SYLLABUS: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let variableName = action.payload.variable;
      let subjects =
        newState.candidateDetails[searchIndex][variableName].subjects;

      let deletedSyllabusList = subjects.filter(
        (v, i) => i !== action.payload.index
      );

      newState.candidateDetails[searchIndex][variableName].subjects =
        deletedSyllabusList;
      return newState;
    }

    case actionTypes.CHANGE_ISACTIVE_FLAG: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      let variableName = action.payload.variable;
      let subjects =
        newState.candidateDetails[searchIndex][variableName].subjects;

      subjects[action.payload.index].isActive = false;
      return newState;
    }

    case actionTypes.UPDATE_EVIDENCE_UPLOADED_STATUS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails[0].uploadedEvidence = action.payload.uploadedEvidenceStatus;
      return newState;
    }

    case actionTypes.UPDATE_FILE_UPLOAD: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      const evidenceDetails =
        newState.candidateDetails[searchIndex].evidenceDetails;
      const file = {
        evidenceID: "",
        evidenceFilepath: action.payload.filePath,
        evidenceFilename: action.payload.fileName,
        evidenceTimestamp: action.payload.uTCTimestamp,
        evidenceStatus: "",
      };
      evidenceDetails.push(file);
      newState.candidateDetails[searchIndex].uploadedEvidence = true;
      newState.candidateDetails[searchIndex].evidenceDetails = evidenceDetails;
      return newState;
    }

    case actionTypes.UPDATE_FILE_UPLOAD_STATUS: {
      const newState = JSON.parse(JSON.stringify(state));

      if (action.payload.calledFromFlag === "uploadaws") {
        const searchIndex = 0;
        newState.candidateDetails[searchIndex].evidenceDetails.map((ele) => {
          if (ele.evidenceFilepath === action.payload.filePath) {
            ele.evidenceStatus = action.payload.fileStatus;
          }
          return ele;
        });
      } else if (action.payload.calledFromFlag === "homeAwsWebsocketResponse") {
        newState.candidateDetails.map((item) => {
          return item.evidenceDetails.map((ele) => {
            if (ele.evidenceFilepath.slice(1) === action.payload.filePath) {
              ele.evidenceStatus = action.payload.fileStatus;
            }
            return ele;
          });
        });
      }
      return newState;
    }

    case actionTypes.DELETE_EVIDENCE_FILE: {
      const newState = JSON.parse(JSON.stringify(state));
      if (action.payload.deleteType === "userDelete") {
        newState.candidateDetails.map((item, index) => {
          let newEvidenceDetails = [];
          item.evidenceDetails.map((ele) => {
            if (
              ele.evidenceFilepath === action.payload.filePath &&
              ele.evidenceStatus === "UPLOADED"
            ) {
              ele.evidenceStatus = "DELETED";
              newEvidenceDetails.push(ele);
            } else if (ele.evidenceFilepath !== action.payload.filePath) {
              newEvidenceDetails.push(ele);
            }
            return "";
          });
          newState.candidateDetails[index].evidenceDetails = newEvidenceDetails;
          if (newState.candidateDetails[index].evidenceDetails.length === 0) {
            newState.candidateDetails[index].uploadedEvidence = false;
          }
          return "";
        });
      }
      return newState;
    }

    case actionTypes.CHANGE_UPLOADED_EVIDENCE_FLAG: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = newState.candidateDetails.findIndex(
        (v) => v.candidateUUID === action.payload.candidateDetails.candidateUUID
      );
      newState.candidateDetails[searchIndex].uploadedEvidence =
        action.payload.riskFlag;
      return newState;
    }

    case actionTypes.ADD_NEW_CANDIDATE_UUID: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails[action.payload.candidateId].candidateUUID =
        uuidv4();
      return newState;
    }

    case actionTypes.UPDATE_FILE_EVIDENCE_STATUS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails.map((item) => {
        return item.evidenceDetails.map((ele) => {
          ele.evidenceStatus = action.payload.fileStatus;
          return ele;
        });
      });
      return newState;
    }

    case actionTypes.CHECKIS_FILEUPLOAD_STUCK_ERROR: {
      const fileScanInProgressStatus = ["Checking for viruses"];
      const uTCEndTime = moment
        .tz(new Date(), "Europe/London")
        .format("YYYY-MM-DD HH:mm:ss");
      const newState = JSON.parse(JSON.stringify(state));
      if (typeof newState.candidateDetails !== "undefined") {
        newState.candidateDetails.map((item) => {
          item.evidenceDetails && item.evidenceDetails.map((ele) => {
            const utcStartTime = moment(
              ele.evidenceTimestamp,
              "YYYYMMDDHHmmss"
            ).format("YYYY-MM-DD HH:mm:ss");
            const startTime = moment(utcStartTime);
            const endTime = moment(uTCEndTime);
            let secondsDuration = endTime.diff(startTime, "seconds");
            if (
              secondsDuration >= 90 &&
              fileScanInProgressStatus.includes(ele.evidenceStatus)
            ) {
              ele.evidenceStatus =
                "Error: There was a problem uploading this file. Please delete it and re-upload.";
            }
            return ele;
          });
          return item;
        });
      }
      return newState;
    }

    case actionTypes.UPDATE_US_EVIDENCE_DETAILS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails[0].usEvidenceType = action.payload.usEvidenceType;
      newState.candidateDetails[0].usEvidenceSummary = action.payload.usEvidenceSummary;
      newState.candidateDetails[0].usEvidenceExp = action.payload.usEvidenceExp;
      newState.candidateDetails[0].usEvidencePrev = action.payload.usEvidencePrev;
      newState.candidateDetails[0].usEvidencePrevText = action.payload.usEvidencePrevText;
      return newState;
    }

    case actionTypes.RESET_STORE: {
      let newState = JSON.parse(JSON.stringify(state));
      newState = INITIAL_STATE;
      return newState;
    }
    case actionTypes.UPDATE_REQID: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.reqNumber = action.payload.reqNumber;
      newState.requestID = action.payload.requestID;
      return newState;
    }

    case 'GET_CANDIDATE_FULL_DETAILS': {
      let newState = JSON.parse(JSON.stringify(state));
      newState = action.payload;
      return newState;
    }

    case 'ADD_NEW_STUDENT': {
      const newState = JSON.parse(JSON.stringify(state));
      newState.candidateDetails.splice(1);
      newState.candidateDetails[0] = {...newCandidateDetailsObj};
      newState.candidateDetails[0].candidateUUID = uuidv4();
      newState.candidateDetails[0].requestID = newState.requestID;
      return newState;
    }

    case actionTypes.SAVE_CANDIDATE_INITIAL_DETAILS: {
      const newState = JSON.parse(JSON.stringify(state));
      let searchIndex = 0;
      
      newState.candidateDetails[searchIndex].candidateNumber =
        action.payload.candidateNumber;
      newState.candidateDetails[searchIndex].firstName =
        action.payload.candidateFirstname;
      newState.candidateDetails[searchIndex].lastName =
        action.payload.candidateLastname;
      return newState;
    }
    case actionTypes.CANDIDATE_NUMBER: {
      const newState = JSON.parse(JSON.stringify(state));
      let searchIndex = action.payload.id;
      newState.candidateDetails[searchIndex].candidateNumber =
        action.payload.candidateNumber;
      newState.candidateDetails[searchIndex].firstName =
        action.payload.candidateFirstname;
      newState.candidateDetails[searchIndex].lastName =
        action.payload.candidateLastname;
      return newState;
    }
    case actionTypes.ADD_NEW_CANDIDATE: {
      return {
        ...state,
        candidateDetails: [
          ...state.candidateDetails,
          {
            isActive: true,
            arrangementStatus: null,
            candidateUUID: uuidv4(),
            status: null,
            qualificationLevel: null,
            requestID: null,
            candidateID: null,
            firstName: "",
            lastName: "",
            updatedStatus: "Added"
          },
        ],
      };
    }
    case actionTypes.UPDATE_STATUS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.reqStatus = action.payload;
      return newState;
    }

    case actionTypes.SET_UPDATED_STATUS_FLAG: {
      const newState = JSON.parse(JSON.stringify(state));
      const searchIndex = 0;
      newState.candidateDetails[searchIndex].updatedStatus =
        action.payload.flag;
      return newState;
    }

    case actionTypes.GET_CALL_RESPONSE: {
      let newState = JSON.parse(JSON.stringify(state));
      newState = action.payload;
      return newState;
    }

    case actionTypes.REORDER_THE_ARRANGEMENT: {
      let newState = JSON.parse(JSON.stringify(state));
      let orderedList = newState.candidateDetails.map((v, i) => {
        const ordered = Object.keys(newState.candidateDetails[i])
          .sort((a, b) => a.localeCompare(b))
          .reduce((obj, key) => {
            obj[key] = newState.candidateDetails[i][key];
            return obj;
          }, {});
        return ordered;
      });
      newState.candidateDetails = orderedList;
      return newState;
    }

    case actionTypes.SAVE_OPTION_ARRAY: {
      const newState = JSON.parse(JSON.stringify(state));
      const removeFromCsv = (values, filterValues) =>
        values
          .split(",")
          .filter((v) => !filterValues.split(",").includes(v))
          .join(",");
      let searchIndex = 0;
      

      function isBlank(str) {
        return !str || /^\s*$/.test(str);
      }

      if (action.payload.flag === true) {
        let optionValues =
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionsValue;

        newState.candidateDetails[
          searchIndex
        ].supplementaryAids.subjects[0].optionsValue = "";

        if (optionValues === "") {
          newState.candidateDetails[
            searchIndex
          ].supplementaryAids.subjects[0].optionsValue =
            optionValues + action.payload.value;
        } else {
          newState.candidateDetails[
            searchIndex
          ].supplementaryAids.subjects[0].optionsValue =
            optionValues + "," + action.payload.value;
        }

        newState.candidateDetails[searchIndex].supplementaryAids.subjects[0] = {
          optionsValue:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionsValue,
          optionOther:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionOther,
          optionArrangementID:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionArrangementID,
          isOptionActive: true,
          optionStatus:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionStatus,
          otherarrangementID:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .otherarrangementID,
          otherStatus:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .otherStatus,
          isOtherActive: true,
          optionComments:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionComments,
          otherComments:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .otherComments,
        };
        optionValues = "";

        let isOptionActiveFlag = newState.candidateDetails[searchIndex]
          .supplementaryAids.subjects[0].optionsValue
          ? true
          : false;
        let isOtherActiveFlag = isBlank(
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionOther
        )
          ? false
          : true;
        newState.candidateDetails[
          searchIndex
        ].supplementaryAids.subjects[0].isOptionActive = isOptionActiveFlag;

        newState.candidateDetails[
          searchIndex
        ].supplementaryAids.subjects[0].isOtherActive = isOtherActiveFlag;
      }
      if (action.payload.flag === false) {
        newState.candidateDetails[searchIndex].supplementaryAids.subjects[0] = {
          optionsValue: removeFromCsv(
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionsValue,
            action.payload.value
          ),
          optionOther:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionOther,
          optionArrangementID:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionArrangementID,
          isOptionActive: true,
          optionStatus:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionStatus,
          otherarrangementID:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .otherarrangementID,
          otherStatus:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .otherStatus,
          isOtherActive: true,
          optionComments:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .optionComments,
          otherComments:
            newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
              .otherComments,
        };
        let isOptionActiveFlag = newState.candidateDetails[searchIndex]
          .supplementaryAids.subjects[0].optionsValue
          ? true
          : false;

        let isOtherActiveFlag = isBlank(
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionOther
        )
          ? false
          : true;
        newState.candidateDetails[
          searchIndex
        ].supplementaryAids.subjects[0].isOptionActive = isOptionActiveFlag;

        newState.candidateDetails[
          searchIndex
        ].supplementaryAids.subjects[0].isOtherActive = isOtherActiveFlag;
      }
      return newState;
    }
    case actionTypes.SAVE_OPTION_PARAM: {
      const newState = JSON.parse(JSON.stringify(state));
      let searchIndex = 0;
      
      function isBlank(str) {
        return !str || /^\s*$/.test(str);
      }

      newState.candidateDetails[searchIndex].supplementaryAids.subjects[0] = {
        optionsValue:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionsValue,
        optionOther: action.payload.param,
        optionArrangementID:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionArrangementID,
        optionComments:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionComments,
        isOptionActive: true,
        optionStatus:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .optionStatus,
        otherarrangementID:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .otherarrangementID,
        otherStatus:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .otherStatus,
        otherComments:
          newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
            .otherComments,
        isOtherActive: true,
      };

      let isOptionActiveFlag = newState.candidateDetails[searchIndex]
        .supplementaryAids.subjects[0].optionsValue
        ? true
        : false;
      let isOtherActiveFlag = isBlank(
        newState.candidateDetails[searchIndex].supplementaryAids.subjects[0]
          .optionOther
      )
        ? false
        : true;
      newState.candidateDetails[
        searchIndex
      ].supplementaryAids.subjects[0].isOptionActive = isOptionActiveFlag;

      newState.candidateDetails[
        searchIndex
      ].supplementaryAids.subjects[0].isOtherActive = isOtherActiveFlag;
      return newState;
    }

    default:
      return state;
  }
};

export default accessArrangementData;
