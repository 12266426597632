import actionTypes from "../actions/actionTypes";

const iState = {
  homePageData: [],
};

const getHomePageDataReducer = (state = iState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOME_PAGE_DATA:
      return {
        ...state,
        homePageData: action.payload,
      };
    default:
      return state;
  }
};

export default getHomePageDataReducer;
