import React from "react";
import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.state = {
      open: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  logErrorToMyService(err, errInfo) {
    return { err, errInfo };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //const errMsg = this.logErrorToMyService(error, errorInfo);
    // console.log(errMsg);
  }

  render() {
    let keyValue = "top center";
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={this.state.hasError}
        message={
          <Typography variant="subtitle1" color="#F7F7F7">Something Went Wrong: Please try again after sometime. <div style={{ left: "30%", position: "relative" }}><a href="/" style={{ color: "#F7F7F7" }}>Back to homepage</a></div></Typography>
        }
        key={keyValue}
      />)
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
