import actionTypes from "../actions/actionTypes";
import content from "../util/content";

const INITIAL_STATE = {
  backUpPayload: {},
  saveBtnClicked: false,
  aaResponseObject: {
    statusText: "",
  },
  addCancandateButtonClicked: false,
  defaultSupplementaryItems: content.supplementaryAidsList,
  isError: "",
  isContinueEditButtonClicked: false,
  applicationRequestNumber: "",
  applicationSeries: "",
  sendMessageStatus: "",
  applicationReqId: null,
  outcomeResultSet: [],
  isAllUploadedFileClean: true,
  isAllFileUploadScanInProgress: false,
  ammendApplication: false,
  saveApplication: "",
  loadHomePageData: false,
  isEnableBtnAllEvidencePresent: true,
  totalActiveSubjects: 0,
  loadConfirmationPageData: false,
  loadCopyPrevSeriesPageData: false,
  loadParentChildHomePageData: false,
  candidateDetails: {
    requestId: null,
    selectedCandidateNumber: "",
    selectedCandidateFirstname: "",
    selectedCandidateLastname: "",
    aaArrangementList: {},
  },
  searchCandidateString: "",
  isSubmitApiResolved: true,
  isGetApiResolved: false,
  allSyllabusCheckboxTaken: true,
  isOTherCentersLoggedIn: false,
  tabNumber: "",
  parentSubmitChildApplication: false,
  childApplications: [],
  getChildOutcomeList: [],
};

const submitPayloadData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.BACK_UP_PAYLOAD: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.backUpPayload = action.payload;
      return newState;
    }
    case actionTypes.GET_CHILD_APPLICATIONS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.childApplications = action.payload;
      return newState;
    }
    case actionTypes.AA_SUBMIT_API_RESPONSE: {
      return {
        ...state,
        aaResponseObject: action.payload,
        loading: false,
      };
    }
    case actionTypes.PARENT_SUBMIT_TO_CHILD: {
      return {
        ...state,
        parentSubmitChildApplication: true
      };
    }
    case actionTypes.TOTAL_ACTIVE_SUBJECTS: {
      return {
        ...state,
        totalActiveSubjects: action.payload,
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actionTypes.SUBMIT_API_STATUS_CHECK: {
      return {
        ...state,
        isSubmitApiResolved: action.payload,
      };
    }
    case actionTypes.GET_API_STATUS_CHECK: {
      return {
        ...state,
        isGetApiResolved: action.payload,
      };
    }
    case actionTypes.LOADING_CANDIDATE_DETAILS: {
      return {
        ...state,
        loadingCandidateDetails: action.payload,
      };
    }
    case actionTypes.ALL_SYLLABUS: {
      return {
        ...state,
        allSyllabusCheckboxTaken: action.payload,
      };
    }
    case actionTypes.ADD_CANDIDATE_BUTTON_CLICKED: {
      return {
        ...state,
        addCancandateButtonClicked: action.payload,
      };
    }
    case actionTypes.LOAD_HOME_PAGE: {
      return {
        ...state,
        loadHomePageData: action.payload,
        applicationStatus: "",
      };
    }
    case actionTypes.LOAD_CONFIRMATION_PAGE: {
      return {
        ...state,
        loadConfirmationPageData: action.payload,
      };
    }

    case actionTypes.LOAD_PARENTCHILD_HOMEPAGE_DATA: {
      return {
        ...state,
        loadParentChildHomePageData: action.payload,
      };
    }

    case actionTypes.LOAD_COPYPREVSERIES_PAGEDATA: {
      return {
        ...state,
        loadCopyPrevSeriesPageData: action.payload,
      };
    }
    case actionTypes.STUDENT_LIST: {
      return {
        ...state,
        candidateNumberList: action.payload,
      };
    }
    case actionTypes.OTHER_CENTERS: {
      return {
        ...state,
        isOTherCentersLoggedIn: action.payload,
      };
    }
    case actionTypes.AA_SUBMIT_API_ERROR: {
      return {
        ...state,
        aaErrorObject: action.payload,
        loading: false,
      };
    }
    case actionTypes.RESET_STORE: {
      return {
        ...state,
        aaResponseObject: {},
        loading: false,
        isError: "",
        parentSubmitChildApplication: false,
        allSyllabusCheckboxTaken: true,
        candidateNumberList: [],
        isSubmitApiResolved: true,
        isGetApiResolved: false,

      };
    }
    case actionTypes.RESET_STATUS: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.aaResponseObject.statusText = "";
      newState.isContinueEditButtonClicked = action.payload;
      return newState;
    }
    case actionTypes.UPDATE_REQID: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.aaResponseObject.data = {
        reqNumber: action.payload.reqNumber,
        requestId: action.payload.requestID,
      };

      return newState;
    }
    case actionTypes.SET_BTN_DISABLED: {
      return {
        ...state,
        btnDisabled: action.payload,
      };
    }
    case actionTypes.AMMEND_APPLICATION: {
      return {
        ...state,
        ammendApplication: action.payload,
        saveApplication: action.saveApplication,
      };
    }
    case actionTypes.SAVE_CANDIDATE_BTN_CLICKED: {
      return {
        ...state,
        saveBtnClicked: action.payload,
      };
    }

    case actionTypes.CHANGE_SUPPLEMENTARY_ITEMS: {
      const currentIndex = state.defaultSupplementaryItems.findIndex(
        (elem) => elem.id === action.payload.reqItem
      );
      const updateList = Object.assign(
        {},
        state.defaultSupplementaryItems[currentIndex]
      );
      updateList.isChecked = action.payload.isTrue;
      const newList = [...state.defaultSupplementaryItems];
      newList[currentIndex] = updateList;
      return {
        ...state,
        defaultSupplementaryItems: newList,
      };
    }
    case actionTypes.RESET_SUPPLEMENTARY_ITEMS: {
      let listItems = [...state.defaultSupplementaryItems];
      listItems = content.supplementaryAidsList;
      return {
        ...state,
        defaultSupplementaryItems: listItems,
        searchCandidateString: "",
      };
    }
    case actionTypes.CANDIDATE_NUMBER_ERROR: {
      return {
        ...state,
        isError: action.payload,
      };
    }

    case actionTypes.SEND_REQUEST_NUMBER: {
      return {
        ...state,
        applicationRequestNumber: action.payload.aaNumbers,
        applicationSeries: action.payload.examSeriesText,
        applicationReqId: action.payload.reqId,
        applicationStatus: action.payload.reqStatus,
      };
    }

    case actionTypes.SEND_MESSAGE: {
      return {
        ...state,
        sendMessageStatus: action.payload,
      };
    }
    case actionTypes.GET_BACK_TO_HOME_PAGE: {
      return {
        ...state,
        applicationRequestNumber: "",
        applicationSeries: "",
        sendMessageStatus: "",
        applicationReqId: null,
        outcomeResultSet: [],
      };
    }
    case actionTypes.GET_CHILD_OUTCOME_RESULT: {
      return {
        ...state,
        getChildOutcomeList: action.payload,
      };
    }

    case actionTypes.GET_OUTCOME_RESULTS: {
      return {
        ...state,
        outcomeResultSet: action.payload.res,
        applicationRequestNumber: action.payload.requestNumbers,
        applicationSeries: action.payload.examSeriesTxt,
        applicationReqId: action.payload.reqId,
      };
    }

    case actionTypes.ALL_UPLOADED_FILES_STATUS: {
      let isAllUploadedFileClean = true;
      let isAllFileUploadScanInProgress = false;
      const fileUpdScanInProgressStatus = ["Uploading", "Checking for viruses"];
      const fileInProcessError = [
        "infected",
        "on",
        "upload failure",
        "Error: There was a problem uploading this file. Please delete it and re-upload.",
      ];

      const aaRequestObjectData = action.payload.data;
      aaRequestObjectData.candidateDetails.forEach((item) => {
        item.evidenceDetails.find((ele) => {
          if (fileInProcessError.includes(ele.evidenceStatus)) {
            isAllUploadedFileClean = false;
          }
          return "";
        });
      });
      aaRequestObjectData.candidateDetails.forEach((item) => {
        item.evidenceDetails.find((ele) => {
          if (fileUpdScanInProgressStatus.includes(ele.evidenceStatus)) {
            isAllFileUploadScanInProgress = true;
          }
          return "";
        });
      });
      return {
        ...state,
        isAllUploadedFileClean: isAllUploadedFileClean,
        isAllFileUploadScanInProgress: isAllFileUploadScanInProgress,
      };
    }

    case actionTypes.RESET_ALL_UPLOADED_FILES_STATUS: {
      return {
        ...state,
        isAllUploadedFileClean: true,
        isAllFileUploadScanInProgress: false,
      };
    }

    case actionTypes.ENABLE_BTN_ALLEVIDENCE_UPLOADED: {
      let isEnableBtnAllEvidencePresent = true;
      const aaRequestObjectData = action.payload.data;
      const evidenceRequiredCandidates =
        action.payload.evidenceRequiredCandidates;
      evidenceRequiredCandidates.forEach((item) => {
        aaRequestObjectData.candidateDetails.find((ele) => {
          if (
            item.candidateUUID === ele.candidateUUID &&
            ele.evidenceDetails.filter(
              (evidence) => evidence.evidenceStatus !== "DELETED"
            ).length === 0
          ) {
            isEnableBtnAllEvidencePresent = false;
          }
          return "";
        });
      });
      return {
        ...state,
        isEnableBtnAllEvidencePresent: isEnableBtnAllEvidencePresent,
      };
    }

    case "SEARCH_CANDIDATE_ACTION": {
      return {
        ...state,
        searchCandidateString: action.payload,
      };
    }

    case "SET_TAB_NUMBER": {
      return {
        ...state,
        tabNumber: action.payload
      }
    }

    default:
      return state;
  }
};

export default submitPayloadData;
